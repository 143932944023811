<template>
    <section class="solutions-area ptb-80">
        <div class="container">
            <div class="section-title">
                <!-- <span class="sub-title">What We Offer</span> -->
                <h2>Fitur Kami</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <img style="width:50%" src="/features/Dengar.png" alt="image">
                        </div>
                        <h3><a href="javascript:;">Dengar Percakapan Dalam Kabin</a></h3>
                        <p>Anda dapat mendengarkan percakapan dalam kabin</p>
                        <!-- <a href="javascript:;" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a> -->
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <img style="width:50%" src="/features/Lacak.png" alt="image">
                        </div>
                        <h3><a href="javascript:;">Lacak Posisi Kendaraan Kendaraan</a></h3>
                        <p>Lacak posisi kendaraan secara langsung melalui aplikasi SEEN</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <img style="width:50%" src="/features/Matikan.png" alt="image">
                        </div>
                        <h3><a href="javascript:;">Matikan Mesin</a></h3>
                        <p>Matikan mesin kendaraan ketika hal yang tidak diinginkan terjadi melalui aplikasi SEEN</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <img style="width:50%" src="/features/SOS.png" alt="image">
                        </div>
                        <h3><a href="javascript:;">SOS Panic Button</a></h3>
                        <p>Panggilan SOS sekali klik melalui aplikasi SEEN</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
    </section>
</template>

<script>
export default {
    name: 'Features'
}
</script>