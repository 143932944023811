import VueRouter from 'vue-router';
import API from '@/config.js'
import Axios from 'axios'
// Pages
import LandingPage from './components/LandingPage';
import Product from './components/other-pages/product/Shop';
import ItemDetails from './components/other-pages/product/ItemDetails';
import PromoList from './components/other-pages/promo/PromoList';
import PromoDetail from './components/other-pages/promo/PromoDetail';
import AboutUs from './components/other-pages/about/AboutUs';
import Faq from './components/other-pages/faq/Faq';
import News from './components/other-pages/blog/News';
import NewsDetails from './components/other-pages/blog/NewsDetails';
import Contact from './components/other-pages/contact/Contact';
import NotFound from './components/other-pages/not-found/NotFound';
import ComingSoon from './components/other-pages/coming-soon/ComingSoon';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SuccessPage from './components/SuccessPage';
import VerificationPage from './components/VerificationPage';

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: LandingPage },
      // { path: '/home', component: LandingPage },
      { path: '/tentang-kami', component: AboutUs },
      
      { path: '/produk', name: 'product', component: Product },
      { path: '/produk/:id', name: 'product-detail', component: ItemDetails },
      
      { path: '/berita', component: News },
      { path: '/berita/:id', component: NewsDetails },
      // { path: '/berita/kategori/:id', component: News },

      { path: '/promo', name: 'promo', component: PromoList },
      { path: '/promo/:id', name: 'promo-detail', component: PromoDetail },

      { path: '/kontak', component: Contact },
      { path: '/faq', component: Faq },
      { path: '/coming-soon', component: ComingSoon },
      { path: '*', component: NotFound },

      { path: '/masuk', component: Login, name: 'login' },
      { path: '/logout/:token', component: Logout, name: 'logout' },      
      { path: '/404', component: NotFound, name: '404' },
      { path: '/daftar', component: Register, name: 'register' },
      { path: '/lupa-password', component: ForgotPassword, name: 'forgot-password' },
      { path: '/account_verification/:key', component: SuccessPage, name: 'success' },
      { path: '/email-verification/:key', component: VerificationPage, name: 'verification' },
      { path: '/reset_password/:key', component: ResetPassword, name: 'reset' },
    ]
});
