<template>
    <div>
        <!-- Start Page Title -->
        <!-- <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Detail Berita</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div> -->
        <!-- End Page Title -->

        <!-- Start Blog Details Area -->
        <section class="blog-details-area mt-4 pb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="prducts-details-image">
                            <div class="section-title mt-4">
                                <h2 style="width: 70%; margin: auto">{{ newsDetail.title }}</h2>
                                <div class="bar"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="blog-details-desc">
                            <div class="article-image">
                                <img :src="newsDetail.cover" alt="image">
                            </div>

                            <div class="article-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><feather type="clock"></feather> <a href="javascript:;">{{ newsDetail.date }}</a></li>
                                        <li><feather type="user"></feather> <a href="javascript:;">Admin</a></li>
                                    </ul>
                                </div>

                                <!-- <h3>{{ newsDetail.title }}</h3> -->

                                <p class="mt-3" align="justify" v-html="newsDetail.content"></p>

                                <!-- <h3 v-if="newsDetail.related.length > 0" class="comment-reply-title">Berita Terkait</h3>
                    
                                <div class="col-md-12">
                                    <div class="row">
                                        <div v-for="(item, index) in newsDetail.related" :key="index" class="col-md-4">
                                            <div class="single-blog-post-item">
                                                <div class="post-image">
                                                    <img :src="item.cover" alt="image">
                                                </div>
                    
                                                <div class="post-content">
                                                    <ul class="post-meta">
                                                        <li><a href="javascript:;">{{ item.category ? item.category.name : '' }}</a></li>
                                                        <li>{{ item.date }}</li>
                                                    </ul>
                                                    <h3 class="mt-0 mb-0" style="font-size: 0.7rem"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item.title }}</router-link></h3>
                                                <router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`" class="read-more-btn">Selengkapnya <i data-feather="plus-circle"></i></router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <!-- <section class="widget widget_search">
                                <div class="search-form">
                                    <label>
                                        <span class="screen-reader-text">Search for:</span>
                                        <input type="search" class="search-field" placeholder="Search...">
                                    </label>
                                    <button type="button"><feather type="search"></feather></button>
                                </div>
                            </section> -->

                            <section class="widget widget_startp_posts_thumb">
                                <h3 class="widget-title">Berita Popular</h3>

                                <article v-for="(item, index) in popularPost" :key="index" class="item">
                                    <a href="javascript:;" class="thumb">
                                        <img :src="item.cover" alt="image">
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">{{ item.date }}</time>
                                        <h4 style="font-size: 12px"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item.title }}</router-link></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                            </section>
                            
                            <section v-if="newsDetail.related.length > 0" class="widget widget_startp_posts_thumb">
                                <h3 class="widget-title">Berita Terkait</h3>

                                <article v-for="(item, index) in newsDetail.related" :key="index" class="item">
                                    <a href="javascript:;" class="thumb">
                                        <img :src="item.cover" alt="image">
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">{{ item.date }}</time>
                                        <h4 style="font-size: 12px"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item.title }}</router-link></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Kategori Berita</h3>

                                <ul>
                                    <li><a href="javascript:;" @click="categoryClick('')">Semua</a></li>
                                    <li v-for="(category, index) in newsCategories" :key="index">
                                        <a href="javascript:;" @click="categoryClick(category.id)">{{ category.name }}
                                        </a>
                                    </li>
                                </ul>
                            </section> -->

                        </aside>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog Details Area -->
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon, SearchIcon, ArrowLeftIcon } from 'vue-feather-icons'

export default {
    name: 'BlogDetails',
    components: {
        ArrowRightIcon, SearchIcon, ArrowLeftIcon
    },
    
    data() {
        return {
            newsDetail: [],
            popularPost: [],

            keyword: '',
        }
    },

    computed: {
        news_id() {
            return this.$route.params.id
        }
    },

    watch: {
        news_id() {
            this.getNewsDetail()
        }
    },

    created() {
        this.getPopularPosts()
        this.getNewsDetail()
    },

    methods: {        
		back() { 
			this.$router.go(-1) //back to previous page
        },
        
        getPopularPosts() {
            axios.get(`${config.API.endpoint}/news/popular`, {
                params: {
                    page: 1,
                    per_page: 3
                }
            }).then(res => {
                const data = res.data.message.data
                this.popularPost = data
            })
        },

        getNewsDetail() {
            axios.get(`${config.API.endpoint}/news/news_detail/${this.news_id}`, {
            }).then(res => {
                const data = res.data.message.data
                this.newsDetail = data
            })
        },
        
    }
}
</script>