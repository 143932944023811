<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Promo</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        
        <section class="hosting-features-area pt-3 bg-f9f6f6">
            <div class="container">

                <div class="row">
                    <div v-for="(item, index) in promoList" :key="index" class="col-lg-3 col-md-6 col-sm-6">
                        <router-link :to="`/promo/${encodeURI(item.title.toLowerCase())}`">
                            <div class="single-works">
                                <img style="width:100%; backgroud: white; margin: 0px auto;" :src="`${item.picture}`" alt="image">
                                <div class="works-content">
                                    <h3 style="color: white">{{ item.title }}</h3>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Shop Details Area -->

        
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
    components: {
        ArrowRightIcon
    },
    name: 'Promos',
    
    data() {
        return {
            promoList: [],
        }
    },

    computed: {
    },

    watch: {
    },

    created() {
        this.getAllPromo()
    },

    methods: {
        getAllPromo() {
            axios.get(`${config.API.endpoint}/promo`, {

            }).then(res => {
                const data = res.data.message.data
                this.promoList = data

            })
        },
    }
}
</script>