<template>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')

export default {
	name: 'Logout',
	components: {

	},
	data() {
		return {
			
    }
  },
  computed: {
      token() {
          return this.$route.params.token
      }
  },

  created() {
      this.doLogout()
  },

  methods: {
    doLogout() {
      axios.post(`${config.API.endpoint}/logout`, {}, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })

      this.$store.commit('deleteUserData')
      this.$router.push('/')
    }
  }
}
</script>