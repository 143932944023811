<template>
    <!-- Start Boxes Area -->    
    <section v-if="newsList.length > 0" class="blog-area ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Berita Terbaru</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="row">
                        <div v-for="(item, index) in newsList" :key="index" class="col-lg-4 col-md-4">
                            <div class="single-blog-post-item">
                                <div class="post-image">
                                        <img :src="item.cover" alt="image">
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta">
                                        <!-- <li><a href="javascript:;">{{ item.author.fullname }}</a></li> -->
                                        <li>{{ item.date }}</li>
                                    </ul>
                                    <h3 style="font-size: 15px"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item. title }}</router-link></h3>
                                    <router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`" class="read-more-btn">Selengkapnya <i data-feather="plus-circle"></i></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link to="/berita" style="float: right" class="read-more">
                        Lihat Berita Lainnya 
                        <arrow-right-icon style="margin-top: -1.5px"></arrow-right-icon>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
    components: {
        ArrowRightIcon
    },
    name: 'News',
    
    data() {
        return {
            newsList: [],
        }
    },

    computed: {
    },

    watch: {
    },

    created() {
        this.getAllNews()
    },

    methods: {
        getAllNews() {
            axios.get(`${config.API.endpoint}/news`, {
                params: {
                    page: 1,
                    per_page: 3
                }
            }).then(res => {
                const data = res.data.message.data
                this.newsList = data

                // console.log(this.newsList)

            })
        },
    }
}
</script>