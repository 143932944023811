<template>
  <div>
    <Banner></Banner>
    <Promos></Promos>
    <News></News>
    <AboutUs></AboutUs>
    <!-- <Funfacts></Funfacts> -->
    <Product></Product>
    <!-- <Features></Features> -->
    <!-- <Ads></Ads> -->
    <Feedback></Feedback>

    <!-- End Ready To Talk Area -->
  </div>
</template>

<script>
import Banner from "./landing-page/Banner";
import News from "./landing-page/News";
import AboutUs from "./landing-page/AboutUs";
import Feedback from "./landing-page/Feedback";
import Partner from "./landing-page/Partner";
import Product from "./landing-page/Product";
import Promos from "./landing-page/Promos";
import Funfacts from "./landing-page/Funfacts";
import Ads from "./landing-page/Ads";
import Features from "./landing-page/Features";

// import { Carousel, Slide } from 'vue-carousel';
import "odometer/themes/odometer-theme-default.css";
import config from "@/config.js";

export default {
  name: "WebHosting",
  components: {
    Banner,
    News,
    AboutUs,
    Feedback,
    Partner,
    Product,
    Promos,
    Funfacts,
    Ads,
    Features,
  },

  created() {
    if (config.GPSID.redirect != "")
      window.location = `${config.GPSID.redirect}`;
  },
};
</script>
