<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Detail Produk</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Shop Details Area -->
        <section class="shop-details-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="products-details-image">
                            <div class="section-title mt-4">
                                <h2>{{ product.name }}</h2>
                                <div class="bar"></div>
                            </div>
                            <center>
                                <img style="width: 100%" :src="product.detail_picture ? product.detail_picture : product.picture" alt="img">
                            </center>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="mb-5 mt-5">
                            <b-tabs>
                                <b-tab title="Deskripsi" active v-html="product.description"></b-tab>
                                <b-tab title="Pemasangan Alat">
                                    <table class="product-table">
                                        <tr>
                                            <td>Alat ini dapat dipasang pada</td>
                                        </tr>
                                        <tr>
                                            <td v-html="product.installation ? product.installation : '-'"></td>
                                        </tr>
                                    </table>
                                </b-tab>
                                <b-tab title="Harga">
                                    <table class="product-table">
                                        <tr>
                                            <td>Harga</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <small style="text-decoration: line-through" v-if="product.old_price">{{ `Rp. ${formatPrice(product.old_price)}` }}</small> 
                                                <b style="margin-left: 5px; font-size: 16px;">{{ `Rp. ${formatPrice(product.new_price)}` }}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Harga sudah termasuk</td>
                                        </tr>
                                        <tr>
                                            <td v-html="product.benefit ? product.benefit : '-'"></td>
                                        </tr>
                                    </table>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="featureList.length > 0">
                        <div class="products-details">
                            <div class="section-title mt-3">
                                <h2 class="text-green">Fitur</h2>
                                <div class="bar"></div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="main-timeline">
                                        <div class="timeline" v-for="(item, index) in featureList" :key="index">
                                            <div class="icon"></div>
                                            <div class="date-content">
                                                <div class="date-outer">
                                                    <img :src="item.icon" alt="image">
                                                </div>
                                            </div>
                                            <div class="timeline-content">
                                                <h5 class="title">{{ item.name }}</h5>
                                                <p class="description" v-html="item.description"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>
        <!-- End Shop Details Area -->

        
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import AddToCartDetails from './AddToCartDetails';
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'ItemDetails',
    components: { 
        Carousel, 
        Slide,
        AddToCartDetails
    },
    data() {
        return {
            product: [],
            labels: [],
            label_id: '',
            featureList: [],
            loading: true
        }
    },
    computed: {
        product_id() {
            return this.$route.params.id
        }
    },
    watch: {
        product() {
            // this.getProductDetail()
            if(this.product.features) this.getFeatureList()
        }
    },

    created() {
        this.getProductDetail()
        // this.getFeatureList()
    },
    mounted() {

    },
    methods: {
        getFeatureList() {
            axios.get(`${config.API.endpoint}/product_feature/filter/${this.product.features}`, {

            }).then(res => {
                const data = res.data.message.data
                this.featureList = data
            }).catch(error => {
                
            })
        },

        getProductDetail() {
            axios.get(`${config.API.endpoint}/product/product_detail/${this.product_id}`, {

            }).then(res => {
                const data = res.data.message.data
                this.product = data
                this.loading = false

            }).catch(error => {
                
            })
        },
        
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
         }
    },
}
</script>