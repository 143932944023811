<template>
    <!-- Start Main Banner -->
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div v-if="isReset" class="col-lg-5">
                            <div class="hero-content"> 
                              <div class="error-content mt-5">
                                <h4>Silakan cek email anda!</h4>
                                <p>Jika belum menerima email, silakan hubungi admin.</p>
                                <router-link class="btn btn-primary" to="/">Kembali ke Beranda</router-link>
                              </div>
                                
                            </div>
                        </div>
                        <div v-else class="col-lg-5 offset-lg-1">
                            <div class="banner-form">
                                <form>                                    
                                   <validation-provider rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input @keydown.enter.prevent="reset" type="email" class="form-control" placeholder="Masukkan Email" v-model="input.email">
                                        <span style="font-size:11px; color:tomato">{{ errors[0] }}</span>
                                    </div>
                                    </validation-provider>

									                  <button type="button" class="btn btn-primary mb-2" @click="reset">
                                      <loader-icon v-show="loading" style="margin-right: 5px" class="spinner"></loader-icon>Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../assets/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { LoaderIcon } from 'vue-feather-icons'

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
	name: 'Forgot Password',
	components: {
    ValidationProvider, extend, required, LoaderIcon
	},
	data() {
		return {
      isReset: false,
      loading: false,
			input: {
        email: '',
			}
		}
  },
  
  created() {

  },

  mounted() {

  },

  methods: {
    reset() {
      if(this.input.email === '') { 
        this.$toasted.show('Masukkan email terlebih dahulu', {
            icons: 'home',
            theme: "bubble", 
            position: "top-right", 
            duration : 3000,
            type: 'error'
        });
      } else {
        this.loading = true
        axios.post(`${config.API.endpoint}/forgot_password`, {
          email: this.input.email,
        }).then(res => {
          this.isReset = true
          this.loading = false
          // this.$router.push('/login')
        }).catch(error => {
          this.loading = false
          if(typeof error.response.data.message !== undefined) {
            this.error = error.response.data.message
          }

          this.$toasted.show(this.error, {
              icons: 'home',
              theme: "bubble", 
              position: "top-right", 
              duration : 3000,
              type: 'error'
          });
        })
      }
    }
  }
}
</script>