<template>
    <!-- Start Features Area -->
    <section class="hosting-features-area ptb-80 bg-f9f6f6">
        <div class="container">
            <div class="section-title">
                <h2>Produk Kami</h2>
                <div class="bar"></div>
                <ol class="breadcrumb product-breadcrumb">
                    <li v-for="(item, index) in productCategoryList" :key="index" class="breadcrumb-item" :class="activeCategory === index ? 'active' : ''" @click="categoryChange(index)">{{ item.name }}</li>
                </ol>
            </div>

            <div class="row">
                <div v-for="(item, index) in productList" :key="index" class="col-lg-4 col-md-6 col-sm-6">
                    <router-link :to="`/produk/${encodeURI(item.name.toLowerCase())}`">
                        <div class="single-works">
                            <img style="width:100%; backgroud: white; margin: 0px auto;" :src="`${item.picture}`" alt="image">
                            <div class="works-content">
                                <h3 style="color: white">{{ item.name }}</h3>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- End Features Area -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
    components: {
        ArrowRightIcon
    },
    name: 'Product',
    data() {
        return {
            productList: [],
            productCategoryList: [],
            activeCategory: -1
        }
    },

    computed: {
    },

    watch: {
        activeCategory() {
            if(this.productCategoryList.length > 0) {
                this.getProduct(this.productCategoryList[this.activeCategory].id)
            }
        }
    },

    created() {
        this.getProductCaegory()
    },

    methods: {
        getProduct(category_id) {
            axios.get(`${config.API.endpoint}/product/category/${category_id}`, {

            }).then(res => {
                const data = res.data.message.data
                this.productList = data
            })
        },

        getProductCaegory() {
            axios.get(`${config.API.endpoint}/product_category`, {

            }).then(res => {
                const data = res.data.message.data
                this.productCategoryList = data

                this.activeCategory = 0
            })
        },

        categoryChange(index) {
            this.activeCategory = index
        }
    }

}
</script>