<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Berita</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div v-if="newsList.length > 0" class="row">
                            <div v-for="(item, index) in newsList" :key="index" class="col-lg-6 col-md-6">
                                <div class="single-blog-post-item">
                                    <div class="post-image">
                                        <img :src="item.cover" alt="image">
                                    </div>
        
                                    <div class="post-content">
                                        <ul class="post-meta">
                                            <li><a href="javascript:;">{{ item.category ? item.category.name : '' }}</a></li>
                                            <li>{{ item.date }}</li>
                                        </ul>
                                        <h3 style="font-size: 15px"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item.title }}</router-link></h3>
                                    <router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`" class="read-more-btn">Selengkapnya <i data-feather="plus-circle"></i></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p>Tidak Ada Data</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget_search">
                                <div disabled class="search-form">
                                    <label>
                                        <span class="screen-reader-text">Search for:</span>
                                        <input v-model="keyword" type="text" class="search-field" placeholder="Cari...">
                                    </label>
                                    <button type="button" @click="getAllNews"><search-icon></search-icon></button>
                                </div>
                            </section>

                            <section class="widget widget_startp_posts_thumb">
                                <h3 class="widget-title">Berita Popular</h3>

                                <article v-for="(item, index) in popularPost" :key="index" class="item">
                                    <a href="javascript:;" class="thumb">
                                        <img :src="item.cover" alt="image">
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">{{ item.date }}</time>
                                        <h4 style="font-size: 12px"><router-link :to="`/berita/${encodeURI(item.title).toLowerCase()}`">{{ item.title }}</router-link></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>
                            </section>

                            <section class="widget widget_categories">
                                <h3 class="widget-title">Kategori Berita</h3>

                                <ul>
                                    <li><a href="javascript:;" @click="categoryClick('')">Semua</a></li>
                                    <li v-for="(category, index) in newsCategories" :key="index">
                                        <a href="javascript:;" @click="categoryClick(category.id)">{{ category.name }}
                                        </a>
                                    </li>
                                </ul>
                            </section>
                        </aside>
                    </div>
                </div>
                
                <!-- start pagination -->
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">

                        <paginate 
                            :page-count="n_page"
                            :click-handler="pageClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination justify-content-center'"
                            page-class="page-item"
                            page-link-class="page-link"
                            prev-class="page-item"
                            prev-link-class="page-link"
                            next-class="page-item"
                            next-link-class="page-link"
                            :initial-page="page"
                        >
                        </paginate>
                    </div>
                </div>
                <!-- end pagination -->

			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon, SearchIcon } from 'vue-feather-icons'

export default {
    name: 'BlogRightSidebarThree',
    components: {
        ArrowRightIcon, SearchIcon
    },
    
    data() {
        return {
            newsList: [],
            popularPost: [],
            newsCategories: [],

            keyword: '',
            category: '',

            page: 1,
            per_page: 6,
            total: 0,
        }
    },

    computed: {
        n_page() {
            return Math.ceil(this.total/this.per_page)
        }
    },

    watch: {
        page() {
            this.getAllNews()
        },
    },

    created() {
        this.getAllNews()
        this.getPopularPosts()
        this.getNewsCategories()
    },

    methods: {
        getAllNews() {
            const params = {
                page: this.page,
                per_page: this.per_page,
            }

            if(this.category) params.news_category_id = this.category
            if(this.keyword) params.search = this.keyword

            axios.get(`${config.API.endpoint}/news`, {
                params: params
            }).then(res => {
                const data = res.data.message.data
                this.newsList = data
                this.total = res.data.message.total
            })
        },

        getPopularPosts() {
            axios.get(`${config.API.endpoint}/news/popular`, {
                params: {
                    page: 1,
                    per_page: 3
                }
            }).then(res => {
                const data = res.data.message.data
                this.popularPost = data
            })
        },

        getNewsCategories() {
            axios.get(`${config.API.endpoint}/news_category`, {
            }).then(res => {
                const data = res.data.message.data
                this.newsCategories = data
            })
        },

         pageClick(page) {
            this.page = page
         },

         categoryClick(category_id) {
             this.category = category_id

             if(this.page == 1) this.getAllNews()
             else this.page = 1
         }
    }
}
</script>