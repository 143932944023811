<template>
  <div v-if="isSubmit">
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h2>Pendaftaran berhasil</h2>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="error-content mt-5">
        <h4>Silakan lakukan konfirmasi email yang telah anda daftarkan!</h4>
        <a href="/" class="btn btn-primary">Kembali ke Beranda</a>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h2>Register</h2>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Checkout Area -->
    <section class="checkout-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="user-actions">
              <i data-feather="edit"></i>
              <span
                >Sudah punya akun?
                <router-link to="/masuk">Klik untuk masuk</router-link>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="billing-details">
              <h3 class="title">Daftar Akun Baru</h3>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Tipe Customer <span class="required">*</span></label>
                    <select
                      class="form-control"
                      :required="true"
                      v-model="input.type_customer"
                    >
                      <option
                        v-for="(item, index) in typeCustomerList"
                        :key="index"
                        v-bind:value="item.value"
                        :selected="item.value == 1"
                        >{{ item.label }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Nama Lengkap <span class="required">*</span></label>
                    <div class="row">
                      <div class="col-lg-3 col-md-3">
                        <select
                          class="form-control"
                          :required="true"
                          v-model="input.prename"
                        >
                          <option
                            v-for="(item, index) in prenameList"
                            :key="index"
                            v-bind:value="item.value"
                            :selected="item.value == 'MR'"
                            >{{ item.label }}</option
                          >
                        </select>
                      </div>

                      <div class="col-lg-9 col-md-9">
                        <input
                          type="text"
                          v-model="input.fullname"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Nomor Telepon <span class="required">*</span></label>
                    <input
                      type="text"
                      v-model="input.phone"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Username <span class="required">*</span></label>
                    <input
                      type="text"
                      v-model="input.username"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Password <span class="required">*</span></label>
                    <input
                      type="password"
                      v-model="input.password"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label
                      >Konfirmasi Password
                      <span class="required">*</span></label
                    >
                    <input
                      type="password"
                      v-model="input.password_confirmation"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Alamat Email <span class="required">*</span></label>
                    <input
                      type="email"
                      v-model="input.email"
                      class="form-control"
                    />
                  </div>
                </div>

                <div v-if="input.type_customer == 2" class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label
                      >Nama Perusahaan <span class="required">*</span></label
                    >
                    <div class="row">
                      <div class="col-lg-3 col-md-3">
                        <select
                          class="form-control"
                          :required="true"
                          v-model="input.prenameco"
                        >
                          <option
                            v-for="(item, index) in prenameCoList"
                            :key="index"
                            v-bind:value="item.value"
                            :selected="item.value == 'PT'"
                            >{{ item.label }}</option
                          >
                        </select>
                      </div>

                      <div class="col-lg-9 col-md-9">
                        <input
                          type="text"
                          v-model="input.company_name"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Alamat Rumah <span class="required">*</span></label>
                    <textarea
                      v-model="input.address"
                      id="notes"
                      cols="30"
                      rows="4"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <p>
                    Anda akan menerima notifikasi email sebagai konfirmasi akun
                    dan menyetujui
                    <router-link to="/">Ketentuan dan kebijakan</router-link>
                    SUPERSPRING
                  </p>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      v-model="isCheck"
                      class="form-check-input"
                      id="create-an-account"
                    />
                    <label class="form-check-label" for="create-an-account"
                      >Saya Setuju</label
                    >
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button
                    :disabled="loading"
                    @click="register"
                    class="btn btn-primary order-btn pull-right"
                  >
                    <loader-icon
                      v-show="loading"
                      style="margin-right: 5px"
                      class="spinner"
                    ></loader-icon
                    >Daftar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "@/config.js";
const axios = require("axios");
import { LoaderIcon } from "vue-feather-icons";

export default {
  name: "Checkout",
  components: {
    LoaderIcon,
  },

  data() {
    return {
      isCheck: false,
      isSubmit: false,
      loading: false,

      input: {
        fullname: "",
        company_name: "",
        username: "",
        password: "",
        password_confirmation: "",
        phone: "",
        email: "",
        address: "",
        type_customer: "1",
        prename: "MR",
        prenameco: "PT",
      },

      typeCustomerList: [
        {
          value: 1,
          label: "individual",
        },
        {
          value: 2,
          label: "company",
        },
      ],

      prenameList: [
        {
          value: "MR",
          label: "MR",
        },
        {
          value: "MRS",
          label: "MRS",
        },
      ],

      prenameCoList: [
        {
          value: "PT",
          label: "PT",
        },
        {
          value: "CV",
          label: "CV",
        },
        {
          value: "TBK",
          label: "TBK",
        },
        {
          value: "Corp",
          label: "Corp",
        },
        {
          value: "Inc",
          label: "Inc",
        },
        {
          value: "UD",
          label: "UD",
        },
        {
          value: "Ltd",
          label: "Ltd",
        },
      ],
    };
  },

  computed: {},

  watch: {},

  mounted() {},

  created() {
    if (config.REGISTER.redirect != "")
      window.location = `${config.REGISTER.redirect}`;
  },

  methods: {
    register() {
      if (this.isCheck) {
        this.loading = true;
        axios
          .post(`${config.API.endpoint}/register`, {
            type_customer: this.input.type_customer,
            prename: this.input.prename,
            fullname: this.input.fullname,
            phone: this.input.phone,
            username: this.input.username,
            prenameco:
              this.input.type_customer == 2 ? this.input.prenameco : "",
            company_name:
              this.input.type_customer == 2 ? this.input.company_name : "",
            email: this.input.email,
            address: this.input.address,
            password: this.input.password,
            password_confirmation: this.input.password_confirmation,
          })
          .then((res) => {
            this.loading = false;
            this.$toasted.show(
              "Pendaftaran akun berhasil, silakan cek email anda",
              {
                icons: "home",
                theme: "bubble",
                position: "top-right",
                duration: 3000,
                type: "success",
              }
            );

            this.isSubmit = true;

            // this.$router.push('/registered')
          })
          .catch((error) => {
            this.loading = false;
            const message = error.response.data.message;
            this.$toasted.show(message, {
              icons: "home",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
              type: "error",
            });
          });
      } else {
        this.loading = false;
        this.$toasted.show(
          "Anda belum menyetujui ketentuan dan kebijakan SUPERSPRING",
          {
            icons: "home",
            theme: "bubble",
            position: "top-right",
            duration: 3000,
            type: "warning",
          }
        );
      }
    },
  },
};
</script>
