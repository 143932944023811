var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-title-area"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"container"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.message)}}),_c('div',{staticClass:"section-title"},[(!_vm.isCheck)?_c('div',{staticClass:"bar"}):_vm._e()])])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),(_vm.isCheck)?_c('div',{staticClass:"error-content mt-5"},[_c('h3',[_vm._v("Silakan login menggunakan akun anda.")]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/masuk"}},[_c('span',[_vm._v("Masuk "),_c('arrow-right-icon',{staticStyle:{"margin-top":"-1.5px"},attrs:{"size":"14"}})],1)])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape5"},[_c('img',{attrs:{"src":require("../assets/img/shape5.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }