<template>
  <div>
    <!-- Start Page Title -->
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h2>Hubungi Kami</h2>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../../../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Contact Info Area -->
    <section class="contact-info-area ptb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <vue-fontawesome icon="whatsapp" size="2" color="#90BE15" />
              </div>
              <h3>Whatsapp 24 Jam</h3>
              <p>08111-877-333</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="map-pin"></feather>
              </div>
              <h3>Kunjungi Kantor Pusat</h3>
              <p>
                <a href="https://g.page/superspring?share" target="_blank"
                  >Jl. Cideng Timur No.81, Petojo Selatan, Kecamatan Gambir,
                  Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10610</a
                >
              </p>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
          >
            <div class="contact-info-box">
              <div class="icon">
                <feather type="phone"></feather>
              </div>
              <h3>Pusat Layanan</h3>
              <p>(021) 2206 2222</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Info Area -->

    <!-- Start Contact Area -->
    <section class="contact-area ptb-80 pt-0">
      <div class="container">
        <div class="section-title">
          <h2>Lebih Dekat dengan Kami</h2>
          <div class="bar"></div>
          <p>
            Customer Care Kami siap menjawab pertanyaan Anda tentang SUPERSPRING
          </p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12">
            <img src="../../../assets/img/1.1.png" alt="image" />
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="input.name"
                    class="form-control"
                    required
                    data-error="Please enter your name"
                    placeholder="Nama"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="input.email"
                    class="form-control"
                    required
                    data-error="Please enter your email"
                    placeholder="Email"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="input.phone"
                    required
                    data-error="Please enter your number"
                    class="form-control"
                    placeholder="No. Telp"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="input.subject"
                    class="form-control"
                    required
                    data-error="Please enter your subject"
                    placeholder="Subjek"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    v-model="input.message"
                    cols="30"
                    rows="5"
                    required
                    data-error="Write your message"
                    placeholder="Pesan Anda"
                  ></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button
                  :disabled="loading"
                  type="submit"
                  @click="submit"
                  class="btn btn-primary"
                >
                  <loader-icon
                    v-show="loading"
                    style="margin-right: 5px"
                    class="spinner"
                  ></loader-icon
                  >Kirim Pesan
                </button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Area -->
  </div>
</template>

<script>
import config from "@/config.js";
const axios = require("axios");
import { LoaderIcon } from "vue-feather-icons";

export default {
  name: "Contact",
  components: {
    LoaderIcon,
  },

  data() {
    return {
      loading: false,

      input: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        type: "",
      },
    };
  },

  created() {
    if (config.KONTAK.redirect != "")
      window.location = `${config.KONTAK.redirect}`;
  },
};
</script>
