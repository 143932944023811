<template>
  <div>
    <Header></Header>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <!-- <PreLoader v-if="isLoading" /> -->
    <router-view></router-view>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <!-- <div v-if="currentUrl == '/login' || currentUrl == '/login'"></div> -->
    <Footer v-else></Footer>
  </div>
</template>

<script>
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import API from '@/config.js'
import Axios from 'axios'
// import PreLoader from './components/layout/PreLoader';
export default {
  name: 'app',
  components: {
    Header, 
    Footer,
    // PreLoader,
  },

  data() {
    return {
      isLoading: true,
      currentUrl: ''
    }
  },

  watch: {
    '$route'(pathUrl){
      this.$store.commit('updateNavbarVisible', false)
      this.currentUrl = pathUrl.path;
      this.isLoading = true;
      setTimeout(() => { this.isLoading = false }, 1500);
    }
  },

  mounted() {
    this.currentUrl = window.location.pathname;
    setTimeout(() => {
      this.isLoading = false
    }, 2000);
  }
}
</script>
