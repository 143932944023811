<template>
    <!-- Start Why Choose US Area -->
    <section class="why-choose-us pt-0 mt-5">
        <div class="container">
            <div class="section-title">
                <h2 class="text-green">Mengapa GPS terbaik ada di SUPERSPRING?</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="benefit-wrapper">
                        <span class="benefit-main-text">BENEFIT</span>
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                        
                        <div class="benefit-icon benefit-icon-1">
                            <img src="benefit-features/safety.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-2">                            
                            <img src="benefit-features/efficiency.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-4">
                            <img src="benefit-features/customize.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-5">
                            <img src="benefit-features/help.png" alt="">
                        </div>
                        
                        <span class="fitur-main-text">FITUR</span>
                        <div class="inner inner-1"></div>
                        <div class="inner inner-2"></div>
                        
                        <div class="fitur-icon fitur-icon-1">
                            <img src="benefit-features/track.png" alt="">                            
                        </div>
                        <div class="fitur-icon fitur-icon-2">
                            <img src="benefit-features/bell.png" alt="">
                        </div>
                        <div class="fitur-icon fitur-icon-3">
                            <img src="benefit-features/engine-off.png" alt="">
                        </div>
                        <div class="fitur-icon fitur-icon-4">
                            <img src="benefit-features/report.png" alt="">
                        </div>

                        <div class="benefit-icon benefit-icon-3 active">
                            <img src="benefit-features/information.png" alt="">
                        </div>
                        
                        <div class="inner-inner">  
                            <!-- BENEFIT -->
                            <div class="benefit-content benefit-content-1">       
                                <h3>Safety</h3>
                                <div class="inner-divider"></div>
                                <p>Kami memberikan rasa aman & ketenangan terhadap aset berharga karena dapat dipantau</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-2">       
                                <h3>Efficiency</h3>
                                <div class="inner-divider"></div>
                                <p>Kami dapat meningkatkan efisiensi & memastikan seluruh kendaraan Anda bekerja dengan optimal</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-3">       
                                <h3>Information</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan informasi seputar perjalanan kendaraan Anda</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-4">       
                                <h3>Customize</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan  sistem & aplikasi yang dapat disesuaikan dengan kebutuhan pelacakan aset Anda</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-5">       
                                <h3>Easy</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan kemudahan pelayanan dengan tersedianya cabang hampir diseluruh  provinsi dan aplikasi yang mudah digunakan</p>
                            </div>
                            
                            <!-- FITUR -->
                            <div class="fitur-content fitur-content-1">       
                                <h3>Track</h3>
                                <div class="inner-divider"></div>
                                <p>Melacak posisi kendaraan Anda secara langsung melalui aplikasi WEB & mobile</p>
                            </div>

                            <div class="fitur-content fitur-content-2">       
                                <h3>Notification</h3>
                                <div class="inner-divider"></div>
                                <p>Menerima notifikasi langsung mengenai status kendaraan Anda saat ini</p>
                            </div>

                            <div class="fitur-content fitur-content-3">       
                                <h3>Engine Off</h3>
                                <div class="inner-divider"></div>
                                <p>Saat terjadi hal yang tidak diinginkan, Anda dapat mematikan mesin kendaraan dari jarak jauh</p>
                            </div>

                            <div class="fitur-content fitur-content-4">       
                                <h3>Reporting</h3>
                                <div class="inner-divider"></div>
                                <p>Dilengkapi berbagai macam informasi seputar status kendaran Anda saat ini atau yang sudah berlalu</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <img src="img1.jpeg" alt="" class="company-image">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <img src="company/1.png" class="company-icon" alt="">
                        </div>
                        <div class="col">
                            <img src="company/2.png" class="company-icon" alt="">
                        </div>
                        <div class="col">
                            <img src="company/3.png" class="company-icon" alt="">
                        </div>
                        <div class="col">
                            <img src="company/4.png" class="company-icon" alt="">
                        </div>
                        <div class="col">
                            <img src="company/5.png" class="company-icon" alt="">
                        </div>
                    </div>

                    
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">     
                    <router-link to="/tentang-kami" style="float: right; margin: 10px auto;" class="read-more text-green">
                        Tentang Kami 
                        <arrow-right-icon style="margin-top: -1.5px"></arrow-right-icon>
                    </router-link>               
                </div>
            </div>
        </div>
    </section>
    <!-- End Why Choose US Area -->
</template>

<script>
import { ArrowRightIcon } from 'vue-feather-icons'
export default {
    components: {
        ArrowRightIcon
    },
    name: 'AboutUs'
}
</script>