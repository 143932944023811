<template>
    <!-- Start Feedback Area -->
    <section class="feedback-area ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Testimoni Pelanggan</h2>
                <div class="bar"></div>
            </div>

            <div class="testimonials-slides">
                <carousel 
                    :autoplay = "true" 
                    :loop = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "5000"
                    :perPageCustom = "[[0, 1],[576, 1],[768, 1],[1200, 1], [1500, 1]]"
                >
                    <slide v-for="(item, index) in testimoni" :key="index">
                        <div class="single-feedback-item" style="min-height: 400px">
                            <div class="client-info align-items-center">
                                <div class="image">
                                    <img :src="item.photo" alt="image">
                                    <!-- <img src="http://placehold.it/300x300" alt="image"> -->
                                </div>

                                <div class="title">
                                    <h3>{{ item.name }}</h3>
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>

                            <p>"{{ item.comment }}"</p>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>

        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
    </section>
    <!-- End Feedback Area -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'Feedback',
    components: { Carousel, Slide },

    data() {
        return {
            testimoni: []
        }
    },

    computed: {
        imgSource() {
            return `${config.API.base_url}/uploads/testimoni`
        }
    },

    created() {
        this.getProduct()
    },

    methods: {
        getProduct() {
            axios.get(`${config.API.endpoint}/testimoni`, {

            }).then(res => {
                const data = res.data.message.data
                this.testimoni = data
            })
        }
    }
}
</script>