<template>
    <section class="funfacts-area ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Kami selalu berusaha memahami harapan konsumen</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="downloaded"
                                :duration="200"
                            />
                        </span>+</h3>
                        <p>Pengguna Gps Tracker</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="feedback"
                                :duration="200"
                            />    
                        </span>+</h3>
                        <p>Dealer</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="workers"
                                :duration="200"
                            />    
                        </span>+</h3>
                        <p>Teknisi</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="contributors"
                                :duration="200"
                            />
                            </span>+</h3>
                        <p>Cabang</p>
                    </div>
                </div>
            </div>

            <div class="map-bg">
                <img src="../../assets/img/map.png" alt="map">
            </div>
        </div>
    </section>
</template>

<script>
import IOdometer from 'vue-odometer';
export default {
    name: 'Funfacts',
    components: {
        IOdometer
    },
    
    data() {
        return {
            contributors: 111,
            workers: 70,
            feedback: 8,
            downloaded: 10
        }
    },
    mounted() {
        const that = this;
        setInterval(function() {
            that.contributors += 1;
        }, 3000);
        setInterval(function() {
            that.workers += 1;
        }, 5000);
        setInterval(function() {
            that.feedback += 1;
        }, 2000);
        setInterval(function() {
            that.downloaded += 1;
        }, 1000);
    }
}
</script>