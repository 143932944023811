<template>
    <!-- Start CTA Area -->
    <section class="pb-3">
        <div style="width:100%">
            <img style="margin-left: auto; margin-right: auto; display: block" src="banner-ads.png" alt="">
        </div>
    </section>
    
    <!-- End CTA Area -->
</template>

<script>
export default {
    name: 'Ads'
}
</script>