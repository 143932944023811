<template>
    <section v-if="promoList.length > 0" class="why-choose-us ptb-80 pt-5">
        <div class="container">
            <div class="section-title">
                <h2 class="text-green">Promo Terkini</h2>
                <div class="bar"></div>
            </div>
            <div class="boxes-slides">
                <carousel 
                    :autoplay = "true" 
                    :loop = "true"
                    :autoplaySpeed = "true"
                    :paginationEnabled = "false"
                    :autoplayTimeout = "3000"
                    :perPageCustom = "[[0, 1],[576, 2],[768, 2],[1200, 3], [1500, 3]]"
                    :scrollPerPage = "false"
                >
                    <slide v-for="(promo, index) in promoList" :key="index">
                        <div class="single-ml-projects-box">
                            <router-link :to="`/promo/${encodeURI(promo.title.toLowerCase())}`"><img  :src="promo.picture" alt="image"></router-link>
                        </div>
                    </slide>

                </carousel>
                
                <router-link to="/promo" class="read-more text-green" style="float: right;">
                    Lihat Promo Lainnya 
                    <arrow-right-icon style="margin-top: -1.5px;"></arrow-right-icon>
                </router-link>

                <div class="contact-cta-box" style="margin-top: 70px;">
                    <h3>Ingin tahu siapa SUPERSPRING?</h3>
                    <p>Silakan baca tentang Kami</p>
                    <router-link class="btn btn-purple" to="/tentang-kami">Tentang Kami</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
    components: {
        ArrowRightIcon
    },
    name: 'Promos',
    
    data() {
        return {
            promoList: [],
        }
    },

    computed: {
    },

    watch: {
    },

    created() {
        this.getAllPromo()
    },

    methods: {
        getAllPromo() {
            axios.get(`${config.API.endpoint}/promo`, {

            }).then(res => {
                const data = res.data.message.data
                this.promoList = data

            })
        },
    }
}
</script>