<template>
    <!-- Start Partner Area -->
    <div class="partner-area partner-section ptb-80 pt-0">
        <div class="container">
            <div class="partner-inner">
                <carousel
                    :perPageCustom = "[[0, 3],[576, 4],[768, 5],[1200, 6], [1500, 6]]"
                    :paginationEnabled = "false"
                    :autoplay = "true"
                    :autoplayTimeout = "4000"
                    :loop = "true"
                    :scrollPerPage = "false"
                    :autoplayHoverPause = "false"
                    :mouseDrag = "false"
                >
                    <slide v-for="(item, index) in partnerList" :key="index">
                        <a href="javascript:;">
                            <img :src="item.logo_shadow" alt="partner">
                            <img :src="item.logo" alt="partner">
                        </a>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
    <!-- End Partner Area -->
</template>

<script>

import config from '@/config.js'
const axios = require('axios')

export default {
  name: 'Partner',
  data() {
    return {
      partnerList: []
    }
  },

  created() {
    this.getPartnerList()
  },

  methods: {
    getPartnerList() {
      axios.get(`${config.API.endpoint}/partner`, {

      }).then(res => {
          const data = res.data.message.data
          this.partnerList = data
      })
    }
  }       
}
</script>