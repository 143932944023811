var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-banner"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row h-100 justify-content-center align-items-center"},[(_vm.isReset)?_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"hero-content"},[_c('div',{staticClass:"error-content mt-5"},[_c('h4',[_vm._v("Silakan cek email anda!")]),_c('p',[_vm._v("Jika belum menerima email, silakan hubungi admin.")]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/"}},[_vm._v("Kembali ke Beranda")])],1)])]):_c('div',{staticClass:"col-lg-5 offset-lg-1"},[_c('div',{staticClass:"banner-form"},[_c('form',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.email),expression:"input.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":"Masukkan Email"},domProps:{"value":(_vm.input.email)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.reset.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "email", $event.target.value)}}}),_c('span',{staticStyle:{"font-size":"11px","color":"tomato"}},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('button',{staticClass:"btn btn-primary mb-2",attrs:{"type":"button"},on:{"click":_vm.reset}},[_c('loader-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner",staticStyle:{"margin-right":"5px"}}),_vm._v("Submit ")],1)],1)])])])])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape5"},[_c('img',{attrs:{"src":require("../assets/img/shape5.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }