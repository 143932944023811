<template>
    <div>
		<!-- Start Page Title -->
        <!-- <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Detail Produk</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div> -->
        <!-- End Page Title -->

		<section class="shop-details-area">
            <div class="container">
				<a href="javascript:;" class="" @click="back">
					<arrow-left-icon size="14" style="margin-top: -1.5px"></arrow-left-icon> kembali
				</a>
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="prducts-details-image">
                            <div class="section-title mt-4">
                                <h2>{{ promo.title }}</h2>
                                <div class="bar"></div>
                            </div>
                            <center>
                                <img style="width: 500px" :src="promo.picture ? promo.picture : promo.picture" alt="img">
                            </center>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="mb-5 mt-5">
						<p style="color: #90C543; font-style: italic;">Berlaku: {{ promo.start_publish +' - '+ promo.end_publish }}</p>
							<div>
								<p align="justify" v-html="promo.description"></p>
							</div>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowLeftIcon, UsersIcon } from 'vue-feather-icons'

export default {
	name: 'Promo',
	components: {
		ArrowLeftIcon,
		UsersIcon
	},
    data() {
        return {
			loading: true,
			promo: []
        }
    },
    computed: {
        promo_id() {
            return this.$route.params.id
        }
    },
    watch: {
    },

    created() {
        this.getPromoDetail()
    },
    mounted() {

    },
    methods: {
		getPromoDetail() {
			axios.get(`${config.API.endpoint}/promo/promo_detail/${this.promo_id}`, {

            }).then(res => {
                const data = res.data.message.data
                this.promo = data
                this.loading = false

            }).catch(error => {
                
            })
		},

		back() { 
			this.$router.go(-1) //back to previous page
		}
	}
}
</script>