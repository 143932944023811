<template>
    <!-- Start Main Banner -->
		<div class="main-slider">
      <carousel 
        :perPage = "1" 
        :autoplay = "true" 
        :autoplayTimeout = "5000" 
        :loop="true" 
        :autoplayHoverPause = "false"
      >

        <!-- <slide class="text-center">
          <video autoplay muted loop style="width: 100%">
            <source src="https://img.gps.id/assets/banner-video.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </slide> -->
        
        <slide v-for="(banner, index) in bannerList" :key="index" class="text-center">
          <img :src="banner.file" alt="">
        </slide>

        <slide v-if="staticBanner" class="text-center">
          <img src="http://placehold.it/1600x600" alt="">
        </slide>

      </carousel>

		</div>
    <!-- End Main Banner -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
	name: 'Banner',
	components: {
    ValidationProvider, extend, required
	},
	data() {
		return {
      bannerList: [],
      staticBanner: false
		}
  },
  
  created() {
    this.getBanner()
  },

  mounted() {

  },

  methods: {
    getBanner() {
      axios.get(`${config.API.endpoint}/banner`, {

      }).then(res => {
        this.bannerList = res.data.message.data
      }).catch(error => {
        this.staticBanner = true
      })
    }
  }
}
</script>