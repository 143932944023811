export default {
    API: {
        base_url: 'https://portal.gps.id',
        endpoint : 'https://portal.gps.id/backend/api',
    },
    SEEN: {
        /* Rredirect Requirement */
        app_id: 2,
        url: 'https://seen.gps.id',
    },
    GPSID: {
    url: "https://gps.id",
    redirect: "https://gps.id"
    }, 
    REGISTER: {
    url: "https://gps.id/register",
    redirect: "https://gps.id/register"
    },
    KONTAK: {
    url: "https://superspring.co.id/contact",
    redirect: "https://superspring.co.id/contact"
    },
    PRODUK: {
    url: "https://superspring.co.id/product",
    redirect: "https://superspring.co.id/product"
    },
}
