import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue } from 'bootstrap-vue'
import VueFeather from 'vue-feather'
import vWow from 'v-wow'
import VueCarousel from 'vue-carousel'
import Toasted from 'vue-toasted'
import App from './App.vue'
import './assets/style/custom.scss'
import { router } from './router'
import store from './store'
import VueGtag from 'vue-gtag'

//Vue-Pagination
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)


import "font-awesome/css/font-awesome.min.css"
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueFeather)
Vue.use(VueRouter)
Vue.use(vWow)
Vue.use(VueCarousel)
Vue.use(Toasted)

Vue.use(VueGtag, {
  config: { id: "UA-178422460-2" }
}, router)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
