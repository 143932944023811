<template>
    <!-- Start Main Banner -->
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div v-if="isCheck && !isReset" class="col-lg-5 offset-lg-1">
                            <div class="banner-form ml-3">
                                <form>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input @keydown.enter.prevent="reset" type="password" class="form-control" placeholder="Masukkan password" v-model="input.password">
                                        <span style="font-size:11px; color:tomato">{{ errors[0] }}</span>
                                    </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label>Password Confirmation</label>
                                        <input @keydown.enter.prevent="reset" type="password" class="form-control" placeholder="Masukkan password" v-model="input.password_confirmation">
                                        <span style="font-size:11px; color:tomato">{{ errors[0] }}</span>
                                    </div>
                                    </validation-provider>

									                  <button type="button" class="btn btn-success mb-2" @click="reset">
                                      <loader-icon v-show="loading" style="margin-right: 5px" class="spinner"></loader-icon>Reset
                                    </button>
                                </form>
                            </div>
                        </div>
                        
                        <div v-if="isReset" class="col-lg-5">
                            <div class="hero-content"> 
                              <div class="error-content mt-5">
                                <h4>Password berhasil diperbaharui!</h4>
                                <p>Silakan login kembali. Senang bisa membantu anda.</p>
                              </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../assets/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { LoaderIcon } from 'vue-feather-icons'

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
	name: 'Login',
	components: {
    ValidationProvider, extend, required, LoaderIcon
	},
	data() {
		return {
      isCheck: false,
      isReset: false,
      loading: false,
			input: {
        password: '',
        password_confirmation: '',
			}
		}
  },
  
  computed: {
      key() {
          return this.$route.params.key
      }
  },
  
  created() {
    this.keyCheck()
  },

  mounted() {

  },

  methods: {
    keyCheck() {
      axios.post(`${config.API.endpoint}/forgot_password/verification`, {
        forgot_key: this.key
      }).then(res => {
        this.isCheck = true
      }).catch(error => {
        this.isCheck = false
        this.$router.push('/not-found')
        // if(typeof error.response.data.message !== undefined) {
        //   this.error = error.response.data.message
        // }

        // this.$toasted.show(this.error, {
        //     icons: 'home',
        //     theme: "bubble", 
        //     position: "top-right", 
        //     duration : 3000,
        //     type: 'error'
        // });
      })
    },

    reset() {
      this.loading = true
      axios.post(`${config.API.endpoint}/reset_password`, {
        forgot_key: this.key,
        password: this.input.password,
        password_confirmation: this.input.password_confirmation,
      }).then(res => {
        this.loading = false
        this.isReset = true
      }).catch(error => {
        this.loading = false
        if(typeof error.response.data.message !== undefined) {
          this.error = error.response.data.message
        }

        this.$toasted.show(this.error, {
            icons: 'home',
            theme: "bubble", 
            position: "top-right", 
            duration : 3000,
            type: 'error'
        });
      })
    }
  }
}
</script>