<template>
    <div>
        <!-- Start Page Title -->
        <section class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Produk Kami</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </section>

        <section class="hosting-features-area ptb-80 bg-f9f6f6">
            <div class="container">
                <div class="section-title">
                    <ol class="breadcrumb product-breadcrumb">
                        <li v-for="(item, index) in productCategoryList" :key="index" class="breadcrumb-item" :class="activeCategory === index ? 'active' : ''" @click="categoryChange(index)">{{ item.name }}</li>
                    </ol>
                </div>

                <div class="row">
                    <div v-for="(item, index) in productList" :key="index" class="col-lg-4 col-md-6 col-sm-6">
                        <router-link :to="`/produk/${encodeURI(item.name.toLowerCase())}`">
                            <div class="single-works">
                                <img style="width:100%; backgroud: white; margin: 0px auto;" :src="`${item.picture}`" alt="image">
                                <div class="works-content">
                                    <h3 style="color: white">{{ item.name }}</h3>
                                    <!-- <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p> -->
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!-- <a style="float: right;" href="javascript:;" class="read-more">
                    Lihat Produk Lainnya 
                    <arrow-right-icon style="margin-top: -1.5px;"></arrow-right-icon>
                </a> -->
            </div>
        </section>
    </div>

</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
    components: {
        ArrowRightIcon
    },
    name: 'Product',
    data() {
        return {
            productList: [],
            productCategoryList: [],
            activeCategory: -1
        }
    },

    computed: {
    },

    watch: {
        activeCategory() {
            if(this.productCategoryList.length > 0) {
                this.getProduct(this.productCategoryList[this.activeCategory].id)
            }
        }
    },

    created() {
        this.getProductCaegory()
    },

    methods: {
        getProduct(category_id) {
            axios.get(`${config.API.endpoint}/product/category/${category_id}`, {

            }).then(res => {
                const data = res.data.message.data
                this.productList = data
            })
        },

        getProductCaegory() {
            axios.get(`${config.API.endpoint}/product_category`, {

            }).then(res => {
                const data = res.data.message.data
                this.productCategoryList = data

                this.activeCategory = 0
            })
        },

        categoryChange(index) {
            this.activeCategory = index
        }
    }

}
</script>