<template>
    <div>
      <!-- Start Page Title -->
      <div class="page-title-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <h2>FAQ</h2>
                    <div class="bar"></div>
                    <ol class="breadcrumb product-breadcrumb">
                        <li v-for="(item, index) in appList" :key="index" class="breadcrumb-item" :class="activeApp === index ? 'active' : ''" @click="faqAppChange(index)">{{ item.name }}</li>
                    </ol>
                </div>
            </div>
			  </div>
			
        <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
      </div>
        <!-- End Page Title -->

        <!-- Start FAQ Area -->
      <section class="faq-area">
        <div class="container">
          <div class="faq-accordion">
            <VueFaqAccordion 
                :items="faqList"
            />
            <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" href="#">Prev</a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">Next</a></li>
                        </ul>
                    </nav>
                </div>
            </div> -->
          </div>                  

          <div class="faq-contact">
            <h3>Berikan Pertanyaan</h3>
            <form>
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <input v-model="input.name" type="text" placeholder="Nama" class="form-control">
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <input v-model="input.email" type="email" placeholder="Email" class="form-control">
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <input v-model="input.phone" type="text" placeholder="Nomor Telepon" class="form-control">
                  </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input v-model="input.subject" type="text" placeholder="Subjek" class="form-control">
                  </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea v-model="input.message" name="message" cols="30" rows="6" placeholder="Pesan" class="form-control"></textarea>
                  </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                  <button :disabled="loading" class="btn btn-primary" type="button" @click="submitQuestion">
                    <loader-icon v-show="loading" style="margin-right: 5px" class="spinner"></loader-icon>Kirim
                    </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
		<!-- End FAQ Area -->
    </div>
</template>

<script>
import config from '@/config.js'
const axios = require('axios')
import VueFaqAccordion from 'vue-faq-accordion';
import { LoaderIcon } from 'vue-feather-icons'

export default {
  name: 'Faq',
  components: {
    VueFaqAccordion, LoaderIcon
  },
  data () {
    return {
      loading: false,

      appList: [],
      faqList: [],
      activeApp: 0,

      input: {        
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        type: '',
      }
    }
  },

  computed: {

  },

  watch: {
    activeApp() {
      if(this.appList.length > 0) {
        this.getFaq(this.appList[this.activeApp].id)
      }
    }
  },

  created() {
    this.getAppList()
  },

  methods: {
    getAppList() {
      axios.get(`${config.API.endpoint}/faq/app`, {
        
      }).then(res => {
        const data = res.data.message.data
        this.appList = data
        this.getFaq(this.appList[0].id)
      }).catch(error => {

      })
    },

    getFaq(app_id) {
      axios.get(`${config.API.endpoint}/faq`, {

        params: {
         app_id: app_id
        }

      }).then(res => {
        const data = res.data.message.data                
        const faq = []
        data.map(item => {
          faq.push({
            title: item.question,
            value: item.answer
          })
        })

        this.faqList = faq
      }).catch(error => {

      })
    },
    
    faqAppChange(index) {
        this.activeApp = index
    },

    submitQuestion() {
      if(this.input.name === '' && this.input.email === '' && this.input.phone === '' && this.input.subject === '' && this.input.message === ''){
        this.$toasted.show('Lengkapi data terlebih dahulu sebelum tekan tombol Kirim!', {
            icons: 'home',
            theme: "bubble", 
            position: "top-right", 
            duration : 3000,
            type: 'error'
        });
      } else {        
        this.loading = true
        axios.post(`${config.API.endpoint}/feedback/add`, {
          app_id: this.appList[this.activeApp].id,
          type: 2,
          name: this.input.name,
          email: this.input.email,
          phone: this.input.phone,
          subject: this.input.subject,
          message: this.input.message,

        }).then(res => {
          this.loading = false
          this.$toasted.show('Terima kasih telah menghubungi kami', {
            icons: 'home',
            theme: "bubble", 
            position: "top-right", 
            duration : 3000,
            type: 'success'
          });
          
          location.reload();

        }).catch(error => {
          this.loading = false  
          const message = error.response.data.message
          this.$toasted.show(message, {
            icons: 'home',
            theme: "bubble", 
            position: "top-right", 
            duration : 3000,
            type: 'error'
          });
        })
      }
    }
  }
}
</script>