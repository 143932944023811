<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Tentang Kami</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
		<!-- End Page Title -->

        <!-- Start About Area -->
		<section class="about-area ptb-80">
			<div class="container">
				<div class="row">
					<!-- <div class="col-lg-6 col-md-12">
						<div class="about-image">
							<img src="../../../assets/img/1.png" alt="image">
						</div>
					</div> -->

					<div class="col-lg-12 col-md-12">
						<div class="about-content">
							<div class="section-title">
								<h2 style="text-align: right">Solusi Keamanan bagi semua</h2>
								<hr>
								<p>SUPERSPRING adalah perusahaan teknologi yang berfokus pada GPS Tracker (GPS Pelacak) dengan fokus utama untuk meningkatkan efisiensi dan juga mendapatkan rasa aman serta membuat anda merasakan pengalaman baru dalam mengelola aset anda dengan basis Teknologi Informasi.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End About Area -->

		<section class="blog-area">
			<div class="container">
				<div class="section-title">
					<h2 class="text-green">Lintas waktu</h2>
					<div class="bar"></div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<img src="/Milestone.jpg" alt="">
					</div>
				</div>
			</div>
		</section>

		<section class="why-choose-us pt-0 ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Mengapa GPS terbaik ada di SUPERSPRING?</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="benefit-wrapper">
                        <span class="benefit-main-text">BENEFIT</span>
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                        
                        <div class="benefit-icon benefit-icon-1">
                            <img src="benefit-features/safety.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-2">                            
                            <img src="benefit-features/efficiency.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-4">
                            <img src="benefit-features/customize.png" alt="">
                        </div>
                        <div class="benefit-icon benefit-icon-5">
                            <img src="benefit-features/help.png" alt="">
                        </div>
                        
                        <span class="fitur-main-text">FITUR</span>
                        <div class="inner inner-1"></div>
                        <div class="inner inner-2"></div>
                        
                        <div class="fitur-icon fitur-icon-1">
                            <img src="benefit-features/track.png" alt="">                            
                        </div>
                        <div class="fitur-icon fitur-icon-2">
                            <img src="benefit-features/bell.png" alt="">
                        </div>
                        <div class="fitur-icon fitur-icon-3">
                            <img src="benefit-features/engine-off.png" alt="">
                        </div>
                        <div class="fitur-icon fitur-icon-4">
                            <img src="benefit-features/report.png" alt="">
                        </div>

                        <div class="benefit-icon benefit-icon-3 active">
                            <img src="benefit-features/information.png" alt="">
                        </div>
                        
                        <div class="inner-inner">  
                            <!-- BENEFIT -->
                            <div class="benefit-content benefit-content-1">       
                                <h3>Safety</h3>
                                <div class="inner-divider"></div>
                                <p>Kami memberikan rasa aman & ketenangan terhadap aset berharga karena dapat dipantau</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-2">       
                                <h3>Efficiency</h3>
                                <div class="inner-divider"></div>
                                <p>Kami dapat meningkatkan efisiensi & memastikan seluruh kendaraan Anda bekerja dengan optimal</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-3">       
                                <h3>Information</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan informasi seputar perjalanan kendaraan Anda</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-4">       
                                <h3>Customize</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan  sistem & aplikasi yang dapat disesuaikan dengan kebutuhan pelacakan aset Anda</p>
                            </div>
                            
                            <div class="benefit-content benefit-content-5">       
                                <h3>Easy</h3>
                                <div class="inner-divider"></div>
                                <p>Kami menyediakan kemudahan pelayanan dengan tersedianya cabang hampir diseluruh  provinsi dan aplikasi yang mudah digunakan</p>
                            </div>
                            
                            <!-- FITUR -->
                            <div class="fitur-content fitur-content-1">       
                                <h3>Track</h3>
                                <div class="inner-divider"></div>
                                <p>Melacak posisi kendaraan Anda secara langsung melalui aplikasi WEB & mobile</p>
                            </div>

                            <div class="fitur-content fitur-content-2">       
                                <h3>Notification</h3>
                                <div class="inner-divider"></div>
                                <p>Menerima notifikasi langsung mengenai status kendaraan Anda saat ini</p>
                            </div>

                            <div class="fitur-content fitur-content-3">       
                                <h3>Engine Off</h3>
                                <div class="inner-divider"></div>
                                <p>Saat terjadi hal yang tidak diinginkan, Anda dapat mematikan mesin kendaraan dari jarak jauh</p>
                            </div>

                            <div class="fitur-content fitur-content-4">       
                                <h3>Reporting</h3>
                                <div class="inner-divider"></div>
                                <p>Dilengkapi berbagai macam informasi seputar status kendaran Anda saat ini atau yang sudah berlalu</p>
                            </div>
                        </div>
                    </div>

					<!-- <div class="row">
						<div class="col-md-12">
                            <img src="img1.jpeg" alt="" class="company-image">
                        </div>
					</div> -->
                </div>

                
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/6.png" class="company-icon w-50" alt="">
                                </div>
                                <p>SUPERSPRING mempunyai customer care 24 jam non stop sejak 2012​.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/2.png" class="company-icon w-50" alt="">
                                </div>
                                <p>SUPERSPRING meraih penghargaan sebagai PERTAMA di Indonesia pada tahun 2017​.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/5.png" class="company-icon w-50" alt="">
                                </div>
                                <p>Garansi 1 tahun untuk produk-produk SUPERSPRING​.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/1.png" class="company-icon w-50" alt="">
                                </div>
                                <p>SUPERSPRING meraih TOP BRAND Award 2018 & 2019 (Frontier Award).</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/4.png" class="company-icon w-50" alt="">
                                </div>
                                <p>SUPERSPRING mendapatkan dan menerapkan Sistem Manajement Mutu ISO9001:2015​.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="company/3.png" class="company-icon w-50" alt="">
                                </div>
                                <p>SUPERSPRING meraih penghargaan 500 Brand Champion tahun 2019​.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Feedback></Feedback>
    </div>
</template>

<script>
import Feedback from './../../landing-page/Feedback';
export default {
    name: 'AboutStyleOne',
    components: {
        Feedback,
    }
}
</script>