<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom navbar-style-two', {'is-sticky': isSticky}]">
        <div class="startp-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img style="height: 50px" src="/logo-superspring.png" alt="logo" />
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

                    <b-collapse class="collapse navbar-collapse mean-menu" v-model="visible" id="navbarSupportedContent" ref="dropdown" is-nav>
                        <ul class="navbar-nav nav ml-auto mr-3">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">Beranda</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/tentang-kami" class="nav-link">Tentang Kami</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/produk" class="nav-link">Produk</router-link>
                            </li>
                            
                            <!-- <li class="nav-item">
                                <router-link to="/contact" class="nav-link">Kontak</router-link>
                            </li> -->

                            <li class="nav-item">
                                <router-link to="/promo" class="nav-link">Promo</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/berita" class="nav-link">Berita</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/faq" class="nav-link">FAQ</router-link>
                            </li>

                            <li v-if="token !== ''" class="nav-item pr-2"><a href="javascript:;" class="nav-link"><feather type="grid" style="vertical-align: top"></feather> APPS</a>
                                <ul class="dropdown_menu right" style="width: 150px">                                    
                                    <li v-for="(item, index) in appList" :key="index" class="nav-item" v-show="myApp.indexOf(item.id) !== -1" style="padding: 5px 0px;">
                                        <div style="padding-left:15px; display: inline" class="hosting-banner-image">
                                            <img style="width:20%" :src="`${item.icon}`" class="wow fadeInDown" v-wow data-wow-delay="1s" alt="image">
                                        </div> 
                                        <a style="display: inline" :href="`${item.url.toString().replace(':token', token)}`" class="nav-link">{{ item.name }}</a>
                                    </li>                                     
                                </ul>
                            </li>
                            <li v-if="token !== ''" class="nav-item ml-2">{{ actor.fullname }} <img class="avatar" width="30px" :src="avatar" alt="">
                                <ul class="dropdown_menu right" style="width: 150px"> 
                                    <li class="nav-item" @click="logout">
                                        <a style="display: inline" href="javascript:;" class="nav-link">Logout</a>
                                    </li>                                    
                                </ul>
                            </li>
                        </ul>
                        <router-link v-if="token === '' && $route.name != 'login'" to="/masuk" class="btn btn-header btn-login">
                            <span>Masuk <arrow-right-icon size="14" style="margin-top: -1.5px"></arrow-right-icon></span>
                        </router-link>
                        <router-link v-if="token === ''  && $route.name != 'register'" to="/daftar" class="btn btn-header btn-register">
                            <span><users-icon size="14" style="margin-top: -1.5px"></users-icon> Daftar</span>
                        </router-link>
                    </b-collapse>
                </nav>
            </div> 
        </div>
        <b-modal 
            v-model="modalShow" 
            no-close-on-esc 
            no-close-on-backdrop 
            hide-header-close 
            title="Verifikasi Akun"
        >
            <b-container>
                <table class="verification-table mb-4">
                    <tr>
                        <td>Email</td>
                        <td class="font-weight-bold">{{ actor.email }}</td>
                        <td :class="actor.is_verified_email ? `text-success` : `text-danger`">{{ actor.is_verified_email ? 'Sudah diverifikasi' : 'Belum diverifikasi' }}</td>
                    </tr>
                    <tr>
                        <td>No. Telp.</td>
                        <td class="font-weight-bold">{{ actor.phone }}</td>
                        <td :class="actor.is_verified_phone ? `text-success` : `text-danger`">{{ actor.is_verified_phone ? 'Sudah diverifikasi' : 'Belum diverifikasi' }}</td>
                    </tr>
                </table>

                <b-row class="mb-1" v-if="!actor.is_verified_email">
                    <label class="control-label col-md-12">Email:</label>
                    <div class="col-md-8">
                        <b-form-input
                            v-model="verification.email"
                            required
                        ></b-form-input>
                    </div>
                    <div class="col-md-4">
                        <b-button :disabled="loading" class="btn-verifikasi" @click="sendEmailVerification()" variant="info">
                            {{ loadingEmail ? `Memuat...` : `Kirim Link`}}
                        </b-button>
                    </div>
                    <small v-if="message.email.text != null" class="col-md-12" :class="`text-${message.email.type}`">{{ message.email.text }}</small>
                </b-row>

                <hr v-if="!actor.is_verified_email">

                <b-row class="mb-1" v-if="!actor.is_verified_phone">
                    <label class="control-label col-md-12">No. Telp:</label>
                    <div class="col-md-8">
                        <b-form-input
                            :disabled="verification.verification_otp != null"
                            v-model="verification.phone"
                            required
                        ></b-form-input>
                    </div>
                    <div class="col-md-4">
                        <b-button v-if="verification.verification_otp == null" :disabled="loading" class="btn-verifikasi" @click="sendOTP()" variant="info">                            
                            {{ loadingPhone ? `Memuat...` : `Kirim OTP`}}
                        </b-button>

                        <b-button v-else 
                            class="btn-verifikasi" variant="warning" @click="verification.verification_otp = null"
                            :disabled="loading"
                        >
                            Ganti Nomor
                        </b-button>
                    </div>
                    <small v-if="message.phone.text != null" class="col-md-12" :class="`text-${message.phone.type}`">{{ message.phone.text }}</small>
                </b-row>

                <b-row v-if="verification.verification_otp" class="mt-3">
                    <label class="control-label col-md-12">OTP:</label>
                    <div class="col-md-8">
                        <b-form-input
                            v-model="verification.otp"
                            required
                        ></b-form-input>
                    </div>
                    <div class="col-md-4">
                        <b-button :disabled="loading || verification.is_verified_phone" class="btn-verifikasi" @click="verifyOtp()" variant="info">                            
                            {{ loadingPhone ? `Memuat...` : `Verifikasi OTP`}}
                        </b-button>
                    </div>
                    <small v-if="message.otp.text != null" class="col-md-12" :class="`text-${message.otp.type}`">{{ message.otp.text }}</small>
                </b-row>
            </b-container>

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        :disabled="loading"
                        variant="dark"
                        size="sm"
                        class="float-right modal-btn ml-2"
                        @click="profile()"
                    >
                        Muat Ulang
                    </b-button>
                    <b-button
                        v-if="actor.enable_skip"
                        :disabled="loading"
                        variant="danger"
                        size="sm"
                        class="float-right modal-btn"
                        @click="modalShow=false; goToSeen()"
                    >
                        Lewati
                    </b-button>
                    <b-button
                        v-else
                        :disabled="loading"
                        variant="danger"
                        size="sm"
                        class="float-right modal-btn"
                        @click="modalShow=false; logout()"
                    >
                        Keluar
                    </b-button>
                </div>
            </template>
        </b-modal>
    </header>
    <!-- End Navbar Area -->
    
</template>

<script>
import config from '@/config.js'
import { ArrowRightIcon, UsersIcon } from 'vue-feather-icons'
const axios = require('axios')

export default {
    components: {
        ArrowRightIcon,
        UsersIcon
    },
    name: 'Header',
    data(){
        return {
            isSticky: false,
            // token: '',
            actor: [],
            // product_category: [],
            appList: [],
            myApp: [],
            avatar: '',
            modalShow: false,

            verification: {
                email: null,
                phone: null,
                otp: null,
                verification_otp: null
            },

            message: {
                email: {
                    type: 'success',
                    text: null
                },
                phone: {
                    type: 'success',
                    text: null
                },
                otp: {
                    type: 'success',
                    text: null
                },
            },

            loading: false,
            loadingEmail: false,
            loadingPhone: false,
        }
    },

    computed: {
        currentRouteName() {
            return this.$route.name
        },

        shoppingCart(){
            return this.$store.state.cart
        },
        
        imgSource() {
            return `${config.API.base_url}/uploads/app_icons`
        },

        token() {
            return this.$store.state.userData.token
        },

        visible: {
            get() {
                return this.$store.state.navbarVisible
            },

            set(value) {
                this.$store.commit('updateNavbarVisible', value)
            }
        }
    },

    watch: {
        token() {
            if(this.token != '') {                
                this.profile()
            }
        },
    },

    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 300){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })


        if(this.token != '') {
            if(this.currentRouteName == 'login') this.$router.push('/')
            this.profile()
        }
    },

    created() {
        this.getApp()
    },

    methods: {
        goToSeen() {
            if(this.actor.privilege == 4 && this.myApp.length == 1 && this.myApp.indexOf(config.SEEN.app_id) != -1) 
                window.location.href = `${config.SEEN.url}/login/${this.token}`
        },
        // getProductCategory() {
        //     axios.get(`${config.API.endpoint}/product_category`, {

        //     }).then(res => {
        //         const data = res.data.message.data
        //         this.product_category = data
        //     }).catch(error => {
        //     })
        // },

        getApp() {
            axios.get(`${config.API.endpoint}/app`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(res => {
                const data = res.data.message.data                
                this.appList = data
            }).catch(error => {

            })
        },

        profile() {
            axios.get(`${config.API.endpoint}/profile`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(res => {
                this.actor = res.data.message.data
                
                let initial = this.actor.fullname.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("")
                this.avatar = `https://placehold.jp/16/499fd8/ffffff/40x40.png?text=${initial}`

                this.myApp = this.actor.apps ? JSON.parse(this.actor.apps.apps) : []

                this.verification.verification_otp = this.actor.verification_otp

                let notValidate = [
                    'success', '404', 'login', 'logout', 'register', 'success', 'verification', 'forgot-password', 'reset'
                ]
                if(notValidate.indexOf(this.currentRouteName) == -1) this.validateUser()
            }).catch(error => {
                if(error.response.status === 401){
                    this.modalShow = false
                    this.$store.commit('deleteUserData')
                    this.$router.push({ path: '/masuk'})
                }
            })
        },

        logout() {
            this.message.email = { type: 'success', text: null }
            this.message.phone = { type: 'success', text: null }
            axios.post(`${config.API.endpoint}/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(() => {
                this.$store.commit('deleteUserData')
                if(this.$route.path != '/') this.$router.push({ path: '/'})
            }).catch(error => {
                if(error.response.status === 401){
                    this.modalShow = false
                    this.$store.commit('deleteUserData')
                    this.$router.push({ path: '/masuk'})
                }
            })
        },

        validateUser() {
            this.verification.email = this.actor.email
            this.verification.phone = this.actor.phone
            if(['login'].indexOf(this.$route.name) == -1) {
                if(this.actor.show_verification && this.token != null) this.modalShow = true
                else this.goToSeen()
            } 
        },

        sendEmailVerification() {
            this.loading = true
            this.loadingEmail = true

            axios.post(`${config.API.endpoint}/user/verification/send_email`, {
                email: this.verification.email
            }, 
            {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(res => {
                this.loading = false
                this.loadingEmail = false

                const data = res.data

                if(data.status) {
                    this.message.email = {
                        type: 'success',
                        text: data.message
                    }
                    this.profile()                
                } else {
                    this.message.email = {
                        type: 'danger',
                        text: data.message
                    }
                }
            }).catch(error => {
                this.loading = false
                this.loadingEmail = false
                if(error.response.status === 401){
                    this.modalShow = false
                    this.$store.commit('deleteUserData')
                    this.$router.push({ path: '/masuk'})
                } else {
                    if(typeof error.response.data.message != 'undefined') {
                        this.message.email = {
                            type: 'danger',
                            text: error.response.data.message
                        }
                    }
                }
            })
        }, 

        sendOTP() {
            this.loading = true
            this.loadingPhone = true

            axios.post(`${config.API.endpoint}/user/verification/send_otp`, {
                phone: this.verification.phone
            }, 
            {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(res => {
                this.loading = false
                this.loadingPhone = false

                const data = res.data

                if(data.status) {
                    this.message.phone = {
                        type: 'success',
                        text: data.message
                    }
                    this.profile()                
                } else {
                    this.message.phone = {
                        type: 'danger',
                        text: data.message
                    }
                }
            }).catch(error => {
                this.loading = false
                this.loadingPhone = false
                if(error.response.status === 401){
                    this.modalShow = false
                    this.$store.commit('deleteUserData')
                    this.$router.push({ path: '/masuk'})
                } else {
                    if(typeof error.response.data.message != 'undefined') {
                        this.message.phone = {
                            type: 'danger',
                            text: error.response.data.message
                        }
                    }
                }
            })
        },

        verifyOtp() {
            this.loading = true
            this.loadingPhone = true

            axios.post(`${config.API.endpoint}/user/verification/verify_otp`, {
                phone: this.verification.phone,
                otp: this.verification.otp
            }, 
            {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(res => {
                this.loading = false
                this.loadingPhone = false

                const data = res.data

                if(data.status) {
                    this.message.otp = {
                        type: 'success',
                        text: data.message
                    }

                    this.verification.is_verified_phone = true
                    // this.profile()                
                } else {
                    this.message.otp = {
                        type: 'danger',
                        text: data.message
                    }
                }
            }).catch(error => {
                this.loading = false
                this.loadingPhone = false
                if(error.response.status === 401){
                    this.modalShow = false
                    this.$store.commit('deleteUserData')
                    this.$router.push({ path: '/masuk'})
                } else {
                    if(typeof error.response.data.message != 'undefined') {
                        this.message.otp = {
                            type: 'danger',
                            text: error.response.data.message
                        }
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.avatar {
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}
</style>