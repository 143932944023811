<template>
    <div><!-- Start Page Title -->
        
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2 v-html="message"></h2>
                        <div class="section-title">
                            <div v-if="!isCheck" class="bar"></div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="shape1"><img src="../assets/img/shape1.png" alt="shape"></div>
            <div class="shape2 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
            <div class="shape3"><img src="../assets/img/shape3.svg" alt="shape"></div>
            <div class="shape4"><img src="../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape5"><img src="../assets/img/shape5.png" alt="shape"></div>
            <div class="shape6 rotateme"><img src="../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape7"><img src="../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="../assets/img/shape2.svg" alt="shape"></div>
            <div v-if="isCheck" class="error-content mt-5">
                <!-- <h3>Silakan login menggunakan akun anda.</h3>
                <router-link to="/masuk" class="btn btn-primary">
                    <span>Masuk <arrow-right-icon size="14" style="margin-top: -1.5px"></arrow-right-icon></span>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
import { ArrowRightIcon, UsersIcon } from 'vue-feather-icons'
import config from '@/config.js'
const axios = require('axios')
export default {
    name: 'SuccessPage',
    components: {
        ArrowRightIcon,
        UsersIcon
    },

    data() {
        return {
            isCheck: false,
            message: 'Loading...',
        }
    },

    computed: {
        key() {
            return this.$route.params.key
        },
    },

    created() {
        this.doVerification()
    },

    methods: {
        doVerification() {
            axios.post(`${config.API.endpoint}/verify_email`, {
                email_verification: this.key
            }).then(res => {
                const data = res.data
                if(data.status) {

                    this.isCheck = true
                    this.message = 'Verifikasi Akun Berhasil'
                } else {
                    this.isCheck = true
                    this.message = data.message
                }
            }).catch(error => {
                this.isCheck = false
                this.$router.push('/not-found')
            })
        }
    }
}
</script>