var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"feedback-area ptb-80"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"testimonials-slides"},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplaySpeed":true,"autoplayTimeout":5000,"perPageCustom":[[0, 1],[576, 1],[768, 1],[1200, 1], [1500, 1]]}},_vm._l((_vm.testimoni),function(item,index){return _c('slide',{key:index},[_c('div',{staticClass:"single-feedback-item",staticStyle:{"min-height":"400px"}},[_c('div',{staticClass:"client-info align-items-center"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":item.photo,"alt":"image"}})]),_c('div',{staticClass:"title"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.title))])])]),_c('p',[_vm._v("\""+_vm._s(item.comment)+"\"")])])])}),1)],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Testimoni Pelanggan")]),_c('div',{staticClass:"bar"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape5"},[_c('img',{attrs:{"src":require("../../assets/img/shape5.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }