<template>
  <section>
    <div>
      <Partner></Partner>
      <!-- Start Ready To Talk Area -->
      <section class="ready-to-talk">
        <div class="container">
          <h3>Silakan hubungi kami</h3>
          <router-link class="btn btn-purple" to="/kontak"
            >Hubungi Kami</router-link
          >
          <p>
            Customer Care Kami siap menjawab pertanyaan Anda tentang SUPERSPRING
          </p>
        </div>
      </section>
    </div>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <div class="logo">
                <a href="/">
                  <!-- <img src="../../assets/img/logo.png" alt="logo" /> -->
                  PT Super Spring
                </a>
              </div>
              <ul class="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/superspringid/"
                    target="_blank"
                    class="facebook"
                    ><feather type="facebook"></feather
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCnCiXRFbOujpXEIqyZTygXg"
                    target="_blank"
                    class="youtube"
                    ><feather type="youtube"></feather
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/superspringid/"
                    target="_blank"
                    class="instagram"
                    ><feather type="instagram"></feather
                  ></a>
                </li>
                <!-- <li><a href="javascript:;" target="_blank" class="linkedin"><feather type="linkedin"></feather></a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-footer-widget pl-5">
              <h3>Perusahaan</h3>
              <ul class="list">
                <li>
                  <router-link to="/tentang-kami">Tentang Kami</router-link>
                </li>
                <!-- <li><a href="javascript:;">Services</a></li> -->
                <!-- <li><a href="javascript:;">Features</a></li> -->
                <li><router-link to="/produk">Produk Kami</router-link></li>
                <!-- <li><a href="javascript:;">Berita Terkini</a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <h3>Dukungan</h3>
              <ul class="list">
                <!-- <li><a href="javascript:;">FAQ's</a></li>
                                <li><a href="/faq">Privacy Policy</a></li>
                                <li><a href="javascript:;">Terms & Condition</a></li>
                                <li><a href="javascript:;">Community</a></li> -->
                <li><router-link to="/kontak">Hubungi Kami</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <h3>Alamat</h3>

              <ul class="footer-contact-info">
                <li>
                  <feather type="map-pin"></feather>
                  <a href="https://g.page/superspring?share" target="_blank"
                    >Jl. Cideng Timur No.81, Petojo Selatan, Kecamatan Gambir,
                    Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10610</a
                  >
                </li>
                <li>
                  <vue-fontawesome
                    icon="whatsapp"
                    size="1"
                    color="#6084a4"
                  ></vue-fontawesome>
                  08111-877-333
                </li>
                <!-- <li><feather type="mail"></feather> Email: support@gps.id</li> -->
                <li><feather type="phone-call"></feather> (021) 2206 2222</li>
              </ul>
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <div class="copyright-area">
              <p>Copyright &copy;2020 SUPERSPRING. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>

      <img src="../../assets/img/map.png" class="map" alt="map" />
      <div class="shape1">
        <img src="../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../assets/img/shape2.svg" alt="shape" />
      </div>
      <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

      <back-to-top bottom="50px" right="50px">
        <div class="go-top"><feather type="arrow-up"></feather></div>
      </back-to-top>
    </footer>
  </section>
  <!-- End Footer Area -->
</template>

<script>
import BackToTop from "vue-backtotop";
import Partner from "./../landing-page/Partner";
export default {
  name: "Footer",
  components: {
    BackToTop,
    Partner,
  },
};
</script>
