var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"quantity"},[_c('span',[_vm._v("Quantity:")]),_c('div',{staticClass:"input-counter"},[_c('span',{staticClass:"minus-btn",on:{"click":_vm.decrement}},[_c('feather',{attrs:{"type":"minus"}})],1),_vm._v(" "+_vm._s(_vm.itemNumber)+" "),_c('span',{staticClass:"plus-btn",on:{"click":_vm.increment}},[_c('feather',{attrs:{"type":"plus"}})],1)])]),_c('button',{on:{"click":function($event){_vm.addToCart({ 
        id: 1, 
        name: 'Wood Pencil', 
        price: 191.00,  
        image: require('../../../assets/img/shop-image/1.jpg')
    })}}},[_vm._v("Add to Cart")]),_c('a',{staticClass:"add-to-wishlist-btn",attrs:{"href":"#","title":"Add to Wishlist"}},[_c('feather',{attrs:{"type":"heart"}})],1),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buy-btn"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"#"}},[_vm._v("Buy it Now")])])
}]

export { render, staticRenderFns }