<template>
  <!-- Start Main Banner -->
  <div class="main-banner">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-5">
              <div class="hero-content">
                <h1>Selamat Datang</h1>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p> -->

                <!-- <a href="javascript:;" class="btn btn-primary">Get Started</a> -->
              </div>
            </div>

            <div class="col-lg-5 offset-lg-1">
              <div class="banner-form ml-3">
                <form>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                      <label>Username</label>
                      <input
                        @keydown.enter.prevent="login"
                        type="text"
                        class="form-control"
                        placeholder="Masukkan username"
                        v-model="input.username"
                      />
                      <span style="font-size:11px; color:tomato">{{
                        errors[0]
                      }}</span>
                    </div>
                  </validation-provider>

                  <validation-provider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        @keydown.enter.prevent="login"
                        type="password"
                        class="form-control"
                        placeholder="Masukkan password"
                        v-model="input.password"
                      />
                      <span style="font-size:11px; color:tomato">{{
                        errors[0]
                      }}</span>
                    </div>
                  </validation-provider>

                  <button
                    id="btnLogin"
                    type="button"
                    class="btn btn-success mb-2"
                    @click="login"
                  >
                    <loader-icon
                      v-show="loading"
                      style="margin-right: 5px"
                      class="spinner"
                    ></loader-icon
                    >Login
                  </button>
                  <router-link style="margin-left: 250px" to="/lupa-password"
                    >Lupa password?</router-link
                  >
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape1"><img src="../assets/img/shape1.png" alt="shape" /></div>
    <div class="shape2 rotateme">
      <img src="../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3"><img src="../assets/img/shape3.svg" alt="shape" /></div>
    <div class="shape4"><img src="../assets/img/shape4.svg" alt="shape" /></div>
    <div class="shape5"><img src="../assets/img/shape5.png" alt="shape" /></div>
    <div class="shape6 rotateme">
      <img src="../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7"><img src="../assets/img/shape4.svg" alt="shape" /></div>
    <div class="shape8 rotateme">
      <img src="../assets/img/shape2.svg" alt="shape" />
    </div>
  </div>
  <!-- End Main Banner -->
</template>

<script>
import config from "@/config.js";
const axios = require("axios");
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { LoaderIcon } from "vue-feather-icons";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "Login",
  components: {
    ValidationProvider,
    extend,
    required,
    LoaderIcon,
  },
  data() {
    return {
      loading: false,
      input: {
        username: "",
        password: "",
      },
    };
  },

  created() {
    if (config.GPSID.redirect != "")
      window.location = `${config.GPSID.redirect}`;
  },

  mounted() {
    if (this.$route.query.apikey) {
      axios
        .post(`${config.API.endpoint}/demo_login`, {
          apikey: this.$route.query.apikey,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status === true) {
            this.$store.commit("updateUserInfo", res.data.message.data);
            this.$router.push("/");
          }
        })
        .catch((error) => {
          this.loading = false;

          if (typeof error.response.data.message !== undefined) {
            this.error = error.response.data.message;
          }

          this.$toasted.show(this.error, {
            icons: "home",
            theme: "bubble",
            position: "top-right",
            duration: 3000,
            type: "error",
          });
        });
    }
  },

  methods: {
    login() {
      if (this.input.username === "" && this.input.password === "") {
        this.$toasted.show("Lengkapi username dan password anda", {
          icons: "home",
          theme: "bubble",
          position: "top-right",
          duration: 3000,
          type: "error",
        });
      } else {
        this.loading = true;
        axios
          .post(`${config.API.endpoint}/single_login`, {
            username: this.input.username,
            password: this.input.password,
          })
          .then((res) => {
            this.loading = false;

            if (res.data.platform == "gps_id_v1") {
              let data = JSON.stringify({
                RequestUserName: this.input.username,
                RequestPassword: this.input.password,
                RequestLanguage: "en",
              });

              window.location = `${config.GPSID.url}/log.php?apiKey=${btoa(
                data
              )}`;
            } else {
              if (res.data.status === true) {
                this.$store.commit("updateUserInfo", res.data.message.data);
                this.$router.push("/");
              }
            }
          })
          .catch((error) => {
            this.loading = false;

            if (typeof error.response.data.message !== undefined) {
              this.error = error.response.data.message;
            }

            this.$toasted.show(this.error, {
              icons: "home",
              theme: "bubble",
              position: "top-right",
              duration: 3000,
              type: "error",
            });
          });
      }
    },
  },
};
</script>
